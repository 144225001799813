
.letters {
  &-chip {
    margin: 3px 4px;
  }

  &-indicators {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  .indicator {
    margin: 0 5px;

    &-active {
      margin: 0 35px 0 5px;
    }
  }
}
