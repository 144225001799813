@import './styles/shared.scss';

.game {
  display: flex;
  justify-content: center;
  flex-flow: column wrap;

  @include md {
    flex-direction: row;
  }
}

.game-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  &:nth-child(2) {
    flex: 2;
  }

  &.mobile {
    display: flex;
    justify-content: space-around;

    h2:last-of-type {
      @include md {
        display: none;
      }
    }
  }

  &:first-of-type {
    margin-top: 20px;

    @include md {
      margin-top: 0;
    }
  }

  &:last-of-type {
    display: none;

    @include md {
      display: inherit;
    }
  }

  h2 {
    font-size: 28px;
    margin: 0;

    @include md {
      font-size: 45px;
    }
    
    @include lg {
      font-size: 50px;
    }
  }
}

.question-list {
  margin-top: 10px;
  font-size: 22px;
  white-space: nowrap;

  @include md {
    font-size: 24px;
  }

  p {
    margin: 5px 0;
    text-align: center;
  }
}

.game-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button:first-of-type {
    margin-right: 20px;
  }

  button:last-of-type {
    margin-left: 20px;
  }
}

.blurred {
  color: transparent;
  text-shadow: 0 0 13px #000;
}