body {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.store-provider {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex: 1;
}

.footer {
  text-align: center;
}