@import '../../styles/shared.scss';

.game-time-slider-wrap {
  display: flex;
  justify-content: space-around;
}

.game-time-slider {
  max-width: 250px;

  @include md {
    max-width: 350px;
  }
}
