.header-title {
  font-size: 20px;
}

.toolbar {
  display: flex;
  justify-content: space-between;
}

button.settings-icon {
  color: white;
}
